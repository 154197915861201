import React, {useEffect, useState} from 'react';
import {
  createMuiTheme,
  CssBaseline,
  Link,
  MuiThemeProvider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useMediaQuery
} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {Version} from "./types";
import BugReportRoundedIcon from '@material-ui/icons/BugReportRounded';
import GradeRoundedIcon from '@material-ui/icons/GradeRounded';

const fetch = require('node-fetch');

type GroupInfo = { [key: string]: string[] }

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    app: {
      maxWidth: "900px",
      margin: "auto"
    },
    toolbar: {
      flexGrow: 1,
      display: "flex",
    },
    formControl: {
      margin: theme.spacing(1),
      height: "100%",
      width: "100%",
    },
    menuItem: {
      minWidth: 130,
    },
    content: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    tableHeader: {
    },
    tableCommitCell: {
      alignItems: "center",
      color: "rgb(75,147,217)"
    },
    tableMessageCell: {
      maxWidth: "450px",
      alignItems: "center"
    },
    tableDebugCell: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "rgb(247,80,76)"
    },
    tableReleaseCell: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "rgb(71,184,89)"
    },
  }),
);

const App: React.FunctionComponent = props => {
  const productInit = "Product"
  const versionInit = "Version"
  const commitInit = "Commit"
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [data, setData] = useState<any>();
  const [groupInfo, setGroupInfo] = useState<GroupInfo>();
  const [product, setProduct] = useState(productInit);
  const [version, setVersion] = useState(versionInit);
  const [commit, setCommit] = useState(commitInit);
  const classes = useStyles();

  const theme = React.useMemo(
    () => createMuiTheme({
      palette: {
        type: prefersDarkMode ? 'dark' : 'light',
        primary: {
          main: "#4B93D9"
        }
      },
    }),
    [prefersDarkMode],
  );

  const filterData = (product: string, version: string, commit: string) => {
    let result: Version[] = []
    if (!product || product === productInit) return []
    result = data[product].versions as Version[]

    result = result.filter(item => {
      return (!version || version === versionInit || item.version === version )
        &&(!commit || commit === commitInit || item.commit.sha === commit)
    })

    return result
  }

  const renderFilteredData = (data: Version[]) => {
    const versions = Array.from(new Set(data.map(item => item.version))).sort().reverse()
    let i = 0

    let result: JSX.Element[] = []

    versions.forEach((versionString, index) => {
      result.push(<TableRow key={i++} className={classes.tableHeader}><TableCell colSpan={4} align={"left"}><Typography><strong>{versionString}</strong></Typography></TableCell></TableRow>)
      data.filter(vs => vs.version === versionString).forEach((_item) => {
        result.push(<TableRow key={i++}>
          <TableCell key={i++}><Typography><Link href={_item.commit.url} className={classes.tableCommitCell}>{_item.commit.sha.slice(0, 7)}</Link></Typography></TableCell>
          <TableCell key={i++}><Typography noWrap className={classes.tableMessageCell}>{_item.commit.message}</Typography></TableCell>
          <TableCell key={i++}><Typography><Link href={_item.links.debug} className={classes.tableDebugCell}><BugReportRoundedIcon />DEBUG</Link></Typography></TableCell>
          <TableCell key={i++}><Typography><Link href={_item.links.release} className={classes.tableReleaseCell}><GradeRoundedIcon />RELEASE</Link></Typography></TableCell>
        </TableRow>)
      })
    })

    return result
  }

  useEffect(() => {
    const fetchData = async () => {
      let _data: any
      if (process.env.NODE_ENV === "development") {
        _data = require("./config.json")
      } else {
        const resp = await fetch(
          'https://raw.githubusercontent.com/dortania/build-repo/builds/config.json'
        );
        _data = await resp.json()
      }
      console.log({_data})
      const _groupInfo: { [key: string]: string[] } = {}

      Object.keys(_data).forEach(key => {
        const item = _data[key]
        if (item.type === undefined) {
          return
        }
        if (_groupInfo[item.type as string] === undefined) {
          _groupInfo[item.type as string] = []
        }
        _groupInfo[item.type as string].push(key)
      })

      setGroupInfo(_groupInfo as GroupInfo)
      setData(_data)
    }

    fetchData()
  }, []);

  let _list: JSX.Element[] = []
  let index: number = 0
  groupInfo && Object.keys(groupInfo).forEach((header) => {
    _list.push(<ListSubheader key={index++}>{header}</ListSubheader>)
    groupInfo[header].forEach((item) => {
      _list.push(<MenuItem className={classes.menuItem} dense key={index++} value={item ? item : "undefined"}>{item}</MenuItem>)
    })
  })

  // update version list
  let versions = [versionInit]
  if (groupInfo && product !== productInit) {
    const _versions = data[product].versions.map((item: { version: string }) => {
      return item.version
    })

    const allVersion = new Set([
      ...versions,
      ..._versions,
    ])
    versions = Array.from(allVersion)

    console.log({versions})
  }

  let commits = [commitInit]
  if (groupInfo && product !== productInit && version !== versionInit) {
    const _commits_arr = data[product].versions.filter((item: { version: string}) => item.version === version).map((item: { commit: any }) => {
      return item.commit["sha"]
    })

    commits = [
      ...commits,
      ..._commits_arr,
    ]

    console.log({ commits })
  }

  return <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <div className={classes.app}>
      <div className={classes.toolbar}>
        <FormControl className={classes.formControl} variant={"outlined"} size={"small"} >
          <Select
            autoWidth
            value={product}
            onChange={(event) => {
              if (event.target.value === undefined) {
                return
              }
              const _product = event.target.value as string
              setProduct(_product)
              setVersion(versionInit)
              setCommit(commitInit)
            }}>
            <MenuItem dense value="Product" className={classes.menuItem}>
              <strong>Product</strong>
            </MenuItem>
            {_list}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl} variant={"outlined"} size={"small"} >
          <Select
            autoWidth
            value={version}
            onChange={(event) => {
              if (event.target.value === undefined) {
                return
              }
              setVersion(event.target.value as string)
              setCommit(commitInit)
            }}>
            <MenuItem className={classes.menuItem} dense value={versionInit}>
              <strong>{versionInit}</strong>
            </MenuItem>
            { versions.length !== 1 && versions.slice(1).map((v, index) => {
              return <MenuItem key={index} className={classes.menuItem} dense value={v}>{v}</MenuItem>
            })}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl} variant={"outlined"} size={"small"} >
          <Select
            autoWidth
            value={commit}
            onChange={(event) => {
              if (event.target.value === undefined) {
                return
              }
              setCommit(event.target.value as string)
            }}>
            <MenuItem className={classes.menuItem} dense value={commitInit}>
              <strong>{commitInit}</strong>
            </MenuItem>
            { commits.length !== 1 && commits.slice(1).map((c, index) => {
              return <MenuItem key={index} className={classes.menuItem} dense value={c}>{c}</MenuItem>
            })}
          </Select>
        </FormControl>
      </div>
      <main className={classes.content}>
        <TableContainer component={Paper}>
          <Table size={"small"}>
            <TableBody>
              {renderFilteredData(filterData(product, version, commit))}
            </TableBody>
          </Table>
        </TableContainer>
      </main>
    </div>
  </MuiThemeProvider>
};


export default App;
